export default {
  computed: {
    buttonName() {
      const buttonName = this.allowedActions.find((data) => {
        return data.name === this.actionName;
      });
      if (buttonName) {
        return buttonName.button_name;
      } else {
        return '';
      }
    },
    isAllowed() {
      if (!this.applicationUuid) {
        return false;
      }
      const applicationType = this.type.toUpperCase(),
        actionType = this.actionType.toUpperCase(),
        actionName = this.actionName.toUpperCase();
      let allowedActions = [];
      if (this.allowedActions) {
        allowedActions = this.allowedActions.map((elem) => {
          return elem.name.toUpperCase();
        });
      }
      if (applicationType === actionType) {
        return allowedActions.indexOf(actionName) !== -1;
      } else {
        return !actionType && allowedActions.indexOf(actionName) !== -1;
      }
    },
  },
  methods: {},
};
